import React, { useContext, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router'

import Footer from './Footer'

import styles from './mainAndFooter.module.css'
import HeaderSide from '../Header/HeaderSide'
// import Menu from '../Menu/Menu'
import { store } from '../HOC/State'

export default function MainContent() {
  const {offset, setOffset, offsetUpdated, setOffsetUpdated} = useContext(store)
  const [atTop, setAtTop] = useState(true)
  const [header, setHeader] = useState({backgroundColor: "transparent", color: "white"})

  let location = useLocation()

  useEffect(() => {
    let scroll = document.getElementById('main-content')
    scroll.scrollTop = 0
    if(location.pathname == "/"){
      setHeader({...header, backgroundColor:"transparent", color: "white", boxShadow: "none"})
    }else{
      setHeader({...header, boxShadow: "0px 5px 5px rgba(168, 167, 167, 0.692)" , backgroundColor:"rgba(226, 226, 223, .)", color: "#2c507b"})
    }
  }, [location.pathname])

  const changeOffset = e => {
      if(e.target.scrollTop == 0){
          setHeader({...header, backgroundColor:"transparent", color: "white", boxShadow: "none"})
          setAtTop(true)
      }else{
        setHeader({...header, boxShadow: "0px 5px 5px rgba(168, 167, 167, 0.692)" , backgroundColor:"rgba(226, 226, 223, 1)", color: "#2c507b"})
        setAtTop(false)
      }
      if(e.target.scrollTop + e.target.clientHeight + 300 + (0.2*window.innerWidth/1.7 * 2) > e.target.scrollHeight && !offsetUpdated){
          setOffset(offset + 20)
          setOffsetUpdated(true)
      }
    }


  return (
    <div>
        <HeaderSide atTop={atTop}/>
        {/* <Menu /> */}
        <div className={styles['main-div']} id='main-content' onScroll={changeOffset} >
            <Outlet/>
            <Footer />
        </div>
    </div>
  )
}
