import React, { useContext, useState } from 'react'
import HomeServices from '../components/HomeServices'
import ImagesSlide from '../components/ImagesSlide'
import styles from './home.module.css'
import { store } from '../components/HOC/State'
import { translate } from './translate'
import Menu from '../components/Menu/Menu'
import FooterMenu from '../components/ContentAndFooter/FooterMenu'
import { menu } from '../components/Menu/menuList'
import Search from '../components/Header/Search'
import Lang from '../components/Header/Lang/Lang'


export default function Home({children}) {
    const {selectedLang} = useContext(store)
    const [selected, setSelected] = useState("")

    let lang = translate[selectedLang]
  return (
    <div>
      <div className={styles['home-div']}>
        {/* <div>
            <div className={styles['gradient']}></div>
            <div className={styles['on-animation']}>
            <div className={styles['name-container']}>
                <div className={styles['name-h']}>
                  <img className={styles['logo-image']} src="/logo.svg" alt="logo" />
                  <div>
                      <h1 >{lang.tr_nameOfOrganization}</h1>
                      <h1>{lang.tr_nameOfOrganization2}</h1>
                  </div>
                </div>
                <div className={styles['search-lang']}>
                  <Search header={{color: "#2c507b"}} from={true}/>
                  <Lang header={{color: "#2c507b"}}/>
                </div>
            </div>

              <div className={styles['menu-on-animation']}>
                 {menu.map(el => (
                    <FooterMenu key={el.category} el={el} selected={selected} setSelected={setSelected}/>
                  ))}  
              
              </div>
           
            </div>
          
          
        </div> */}
        {/* <HomeServices /> */}
        {/* {children} */}
      </div>
       <div className={styles['home-info-div-scroll']}>
         <div className={styles['home-info-div']}>
          <div className={`${styles['home-info']} ${styles['bgdarkblue']}`}>
            <div className={styles['home-info-text']}>
              <div>
                Founded in 1956 and named after the esteemed scientist Sergey Mergelyan, the Yerevan Computer Research Development Institute (YCRDI) has built a remarkable legacy in the field of computing technology. 
              </div>
            </div>
            <div className={`${styles['home-info-image']} ${styles['home-info-image-from-right']}`}>
              <img src="/mergelyan-building.jpg" alt="imageOf"/>
            </div>
          </div>
          <div className={`${styles['home-info']} ${styles['bggray']}`}>
          
            <div className={styles['home-info-text']}>
              <div>
                With a rich history in designing and manufacturing a diverse array of computer technologies and systems, YCRDI has consistently been at the forefront of innovation. As a forward-thinking organization, we leverage our extensive expertise to embrace emerging technologies that drive advancements in both military and industrial sectors.
              </div>
            </div>  
            <div className={`${styles['home-info-image']} ${styles['home-info-image-from-right']}`}>
              <img src="/SergeyMergelyan.jpg" alt="imageOf"/>
            </div>
          </div>
          <div className={`${styles['home-info']} ${styles['bgblue']}`}>
          
          <div className={styles['home-info-text']}>
            <div>
              By focusing on innovation and security, we aim to deliver cutting-edge solutions that uphold the highest standards of technological excellence. Yerevan Computer Research Development Institute – Secure Today Safe Tomorrow.
            </div>
          </div>  
          <div className={`${styles['home-info-image']} ${styles['home-info-image-from-right']}`}>
            <img src="/building.jpg" alt="imageOf"/>
          </div>
        </div>
        <div className={`${styles['home-info']} ${styles['bgdarkblue']}`}>
          
          <div className={styles['home-info-text']}>
            <div>
            Founded in 1956 and named after the esteemed scientist Sergey Mergelyan, the Yerevan Computer Research Development Institute (YCRDI) has built a remarkable legacy in the field of computing technology. 
            </div>
          </div>  
          <div className={`${styles['home-info-image']} ${styles['home-info-image-from-right']}`}>
            <img src="/mergelyan-building.jpg" alt="imageOf"/>
          </div>
        </div>
      </div>
      </div>     
      <ImagesSlide />
    </div>
    
  )
}
