import React, { useContext, useEffect, useState } from 'react'
import { Link, useLoaderData, useLocation } from 'react-router-dom';

import Search from './Search'
import Lang from './Lang/Lang'

import styles from './header.module.css'
import { store } from '../HOC/State';
import { translate } from '../../Pages/translate';
import Menu from '../Menu/Menu';

export default function HeaderSide({atTop}) {
    const {selectedLang} = useContext(store)
    let lang = translate[selectedLang]

    const {pathname} = useLocation()

    if(atTop && pathname == "/"){
        return (
            <div >
                <div className={styles['header-div']}>
                    <div className={styles['name-container']}>
                         <Link to='/' className={styles['logo-div']}>
                          <img className={styles['logo-image']} src="/logo.svg" alt="logo" />
                          <div>
                              <h1 >{lang.tr_nameOfOrganization}</h1>
                              <h1>{lang.tr_nameOfOrganization2}</h1>
                          </div>
                        </Link>
                        <div className={styles['search-lang-end']}>
                          <Search header={{color: "#2c507b"}} from={true}/>
                          <Lang header={{color: "#2c507b"}}/>
                        </div>
                    </div>
                </div>
                <div className={styles['menu']}>
                        <Menu/>
                </div>
            </div>
          )
    }else{
        return (
            <div className={styles['header-div-main']}>
            {<Link to='/' className={styles['logo-div']}>
                    <img className={styles['logo-image-main']} src="/logo.svg" alt="logo" />
                    <div className={styles['name-div']}>
                        <h1 className={styles['name-h']}>YCRDI</h1>
                    </div>
                </Link>}
                
                <div className={styles['search-lang-end']}>
                    <div className={styles['search-lang-div']}>
                        <Menu/>
                        <Search />
                        <Lang/>
                    </div>
                </div>
            </div>
          )
    }

  
}
